import { useEvent } from './../framework'
import { events } from './../events'
import { getChosenVariant } from '../configurator'

export default (ref, __devmode) => {
  if (!ref.mainProductThumbnails) return

  let currentVariantHas3DView = false
  let has3D = false

  ref.mainProductThumbnails.forEach(element => {
    const items = [...element.querySelectorAll('[data-variant]')]

    if (!items.length) return

    function showVariantImage() {
      if (currentVariantHas3DView) return

      const currentVariant = getChosenVariant()

      const noPreviewTxt = document.querySelector('.main-product-no-preview ')
      if (currentVariant == undefined && noPreviewTxt) {
        noPreviewTxt.style.visibility = 'visible'
        noPreviewTxt.style.opacity = 1
      } else {
        noPreviewTxt.style.visibility = 'hidden'
        noPreviewTxt.style.opacity = 0
      }


      if (!currentVariant) return

      const { id } = currentVariant

      const activeItem = items.find(
        item => parseInt(item.dataset.variant) === id
      )

      if (!activeItem || !activeItem.querySelector('img')) return

      items.forEach(item => {
        const { variant } = item.dataset
        const active = parseInt(variant) === id

        item.style.opacity = active ? 1 : 0
        item.style.position = active ? 'relative' : 'absolute'
      })
    }

    function handle3DLoaded({ detail: isLoaded }) {
      has3D = isLoaded

      if (!isLoaded) {
        element.style.removeProperty('display')
      } else {
        // element.remove()
      }
    }

    showVariantImage()

    function toggleVariantImageWhenTheresNo3d({ detail: hasPreview }) {
      currentVariantHas3DView = hasPreview

      if (!hasPreview) {
        element.style.removeProperty('display')
      } else {
        element.style.display = 'none'
      }

      if (__devmode) {
        console.log('hasPreview', hasPreview)
      }

      showVariantImage()
    }

    // useEvent.listen(events.product.hasPreview, toggleVariantImageWhenTheresNo3d)
    useEvent.listen(events.product.variantSelectorUpdate, showVariantImage)
    useEvent.listen(events.bimbear.loaded, handle3DLoaded)
  })
}
