import { events } from '../events'
import { useEvent } from '../framework'
import { round } from './../utils'

export default ref => {
  if (!ref.marquee) return

  function createAndAppendDiv(element, text) {
    const div = document.createElement('div')
    div.textContent = text
    element.append(div)

    return div
  }

  function init(element) {
    const target = element?.firstElementChild || element

    // target.textContent = target.dataset.content
    element.style.removeProperty('--marquee-duration')
    element.classList.remove('marquee-active')

    const { width } = target.getBoundingClientRect()
    const innerWidth = document.body
      .querySelector('main')
      .getBoundingClientRect().width

    const shouldActivate = width >= innerWidth - 40

    element.classList.toggle('marquee-active', shouldActivate)

    const text = target.textContent

    if (shouldActivate) {
      element.innerHTML = ''
      element.style.setProperty(
        '--marquee-duration',
        round(width / (window.innerWidth / 20)) + 's'
      )

      const div = createAndAppendDiv(element, text)

      const copiesNeeded =
        Math.ceil(window.innerWidth / div.getBoundingClientRect().width) + 1

      for (let i = 0; i < copiesNeeded; i++) {
        createAndAppendDiv(element, text)
      }
    } else {
      element.innerHTML = ''
      createAndAppendDiv(element, text)
    }
  }

  const instances = ref.marquee.map(element => {
    element.dataset.content = element.textContent

    init(element)

    return {
      refresh() {
        init(element)
      },
    }
  })

  let lastWidthWidth = window.innerWidth

  useEvent.listen(events.window.resize, () => {
    if (lastWidthWidth !== window.innerWidth) {
      instances.forEach(instance => instance.refresh())
    }

    lastWidthWidth = window.innerWidth
  })
}
