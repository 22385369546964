import { events } from '../events'
import { useEvent } from '../framework'
import { easeInOutCubic } from './../utils'

const fill = link => {
  let progress = 0
  const tick = () => {
    progress += 0.05
    link.style.setProperty(
      '--progress',
      Math.min(1, easeInOutCubic(progress)) * 100
    )
    if (progress < 1) {
      requestAnimationFrame(tick)
    }
  }
  tick()
}

export default ref => {
  if (!ref.cartLink) return

  ref.cartLink.forEach(link => {
    if (!link.classList.contains('empty')) {
      link.style.setProperty('--progress', 100)
      link.classList.add('static')
    }
  })

  useEvent.listen(
    events.cart.updated,
    async ({ detail }) => {
      // setTimeout(async () => {
      try {
        const url = window.Shopify.routes.root + 'cart.js'
        const res = await fetch(url)
        const json = await res.json()

        const lineItemAmount = json.items.reduce(
          (acc, cur) => (acc += cur.quantity),
          0
        )

        ref.cartLink.forEach(link => {
          if (link.classList.contains('empty')) {
            fill(link)
          }

          link.classList.toggle('empty', lineItemAmount <= 0)
          const amountOutput = link.querySelector('.cart-link-amount')
          amountOutput.textContent = lineItemAmount

          setTimeout(() => {
            if (!link.classList.contains('empty')) {
              link.classList.add('static')
            }
          }, 2000)
        })
      } catch (error) {
        console.log(error)
      }
      // }, 1000)
    },
    { global: true }
  )

  // useEvent.listen(
  //   events.cart.loading,
  //   ({ detail }) => {
  //     ref.cartLink.forEach(link => {
  //       link.style.opacity = detail ? 0.3 : 1
  //     })
  //   },
  //   { global: true }
  // )
}
