import DragScroll from './drag-scroll'
import { zeroPad } from '../utils'
import { useRefs } from '../framework'
import { events } from '../events'
import { useEvent } from '../framework'
import { isMobile } from '../utils'

const loopedIndex = (idx, max) => {
  if (idx === -1) return max - 1
  if (idx === max) return 0
  return idx
}

export default ref => {
  if (!ref.featuredProducts && !ref.featuredProduct) return
  if (ref.featuredProducts) {

    function initAll() {
      ref.featuredProducts.forEach(element => {
        const carouselElement = element.querySelector('.ul')
        const prevButton = element.querySelector('.previous')
        const nextButton = element.querySelector('.next')
        const current = element.querySelector('.current')

        const slides = [...element.querySelectorAll('.featured-products-slide')];
        slides.forEach((slide, index) => {
          const foreground = slide.querySelector('.featured-products-foreground')
          const imagesWrapper = foreground.querySelector('.featured-products-blocks')
          const images = [...imagesWrapper.querySelectorAll('img')];
          images.length === 1 ? imagesWrapper.classList.add('single-img') : imagesWrapper.classList.remove('single-img')
        })

        const carousel = DragScroll({
          el: carouselElement,
          item: '.featured-products-slide',
          loop: true,
          snap: true,
          align: 'center',
          lerp: [0.1, 0.1],
          onChange(state, active) {
            if (current) {
              current.textContent = zeroPad(active + 1)
            }

            state.children.forEach(child => {
              const { index } = child.dataset

              const indexInt = parseInt(index)

              child.classList.toggle(
                'previous',
                loopedIndex(active - 1, state.items.length) === indexInt
              )
              child.classList.toggle('active', active === indexInt)
              child.classList.toggle(
                'next',
                loopedIndex(active + 1, state.items.length) === indexInt
              )
              let slideBackground = child.querySelector('.featured-products-background')
              let slideHeight = slideBackground.getBoundingClientRect().height
              let slidePaddingBottom = parseFloat(window.getComputedStyle(child, null).getPropertyValue('padding-bottom'))
              console.log(!child.classList.contains('flipped'))
              useEvent.listen(events.window.resize, () => {
                slideBackground = child.querySelector('.featured-products-background')
                slideHeight = slideBackground.getBoundingClientRect().height
                slidePaddingBottom = parseFloat(window.getComputedStyle(child, null).getPropertyValue('padding-bottom'))
                isMobile() && child.classList.contains('flipped') ? child.style.height = `calc(${slideHeight}px + ${slidePaddingBottom}px)` : null
              })
              isMobile() && child.classList.contains('flipped') ? child.style.height = `calc(${slideHeight}px + ${slidePaddingBottom}px)` : null

            })
          },
        })

        prevButton?.addEventListener('click', () => carousel.navigate(-1))
        nextButton?.addEventListener('click', () => carousel.navigate(1))

        handleSlideToggle(element)

      })
    }

    initAll()
  }
  if (ref.featuredProduct) {
    const featuredProduct = ref.featuredProduct[0]
    const foreground = featuredProduct.querySelector('.featured-products-foreground')
    const imagesWrapper = foreground.querySelector('.featured-products-blocks')
    const images = [...imagesWrapper.querySelectorAll('img')];
    images.length === 1 ? imagesWrapper.classList.add('single-img') : imagesWrapper.classList.remove('single-img')
    handleSlideToggle(featuredProduct)
  }
  function handleSlideToggle(element) {
    const refs = useRefs({ target: element, asArray: true })

    function handleToggle(button) {
      const slide = button.closest('[data-ref="featured-products-slide"]')

      slide.classList.toggle('flipped')
      slide.classList.contains('flipped') ? null : slide.style.removeProperty('height');
    }

    refs.featuredProductsToggle.forEach(button => {
      button.addEventListener('click', () => handleToggle(button))
    })

  }
  // window.addEventListener('shopify:section:load', initAll)
}
