// import { nextTick } from '@barba/core/dist/core/src/utils/helpers'
import { delay } from '../utils'

const duration = 200

const fullscreen = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100vw',
  zIndex: 1,
}

export default {
  leave({ current }, ref) {
    return new Promise(async resolve => {
      const storesSections = [...current.container.querySelectorAll('.stores')]
      const storesMenu = document.querySelector('#stores-menu')
      // console.log('store menu: ', storesMenu)
      storesMenu.style.transition = `opacity ${duration}ms`
      storesMenu.style.opacity = 0
      if (!storesSections.length) {
        const container = current.container.querySelector(
          '.barba-translate-element'
        )
        container.style.transition = `opacity ${duration}ms`
        container.style.opacity = 0
      } else {
        storesSections.forEach(section => {
          section.style.transition = `opacity ${duration}ms`
          section.style.opacity = 0
        })
      }
      setTimeout(resolve, duration)
    })
  },
  enter({ next, trigger }, ref) {
    return new Promise(async resolve => {
      const storesMenu = document.querySelectorAll('#stores-menu')
      Object.assign(next.container.style, fullscreen)

      const storesSections = [...next.container.querySelectorAll('.stores')]
      const container = next.container.querySelector('.barba-translate-element')

      if (!storesSections.length) {
        container.style.opacity = 0
      } else {
        storesSections.forEach(section => {
          section.style.opacity = 0
        })
      }
      storesMenu[0].style.opacity = 0
      storesMenu[1].style.opacity = 0

      await delay(10)

      if (!storesSections.length) {
        container.style.transition = `opacity ${duration}ms`
        container.style.opacity = 1
      } else {
        storesSections.forEach(section => {
          section.style.transition = `opacity ${duration}ms`
          section.style.opacity = 1
        })
      }
      storesMenu[0].style.transition = `opacity ${duration}ms`
      storesMenu[1].style.transition = `opacity ${duration}ms`
      storesMenu[1].style.opacity = 1

      setTimeout(() => {
        resolve()
      }, duration)
    })
  },
}
