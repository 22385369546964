import { events } from '../events'
import { useEvent } from '../framework'

export default ref => {
  if (!ref.mainProductHasPreviewLabel) return

  function toggleLabels({ detail: hasPreview }) {
    console.log('has preview', hasPreview)
    ref.mainProductHasPreviewLabel.forEach(element => {
      element.style.display = !hasPreview ? 'block' : 'none'
    })
  }

  useEvent.listen(events.bimbear.loaded, toggleLabels)
  useEvent.listen(events.product.hasPreview, toggleLabels)
}
