import DragScroll from './drag-scroll'
import { useApp, useHydrate, useRefs } from './../framework'
import { isMobile } from '../utils'

export default (ref, __devmode) => {
  if (!ref.carousel) return

  const carousels = ref.carousel
    .map(element => {
      if (!element.children.length) return

      const { noAutoplay, responsive } = element.dataset

      const items = element.querySelectorAll('.li')

      if (
        responsive !== undefined &&
        ((isMobile() && items.length <= 3) ||
          (!isMobile() && items.length <= 6))
      ) {
        element.classList.add('destroyed')
        return
      }

      const carousel = DragScroll({
        el: element,
        item: '.li',
        loop: true,
        autoplay: noAutoplay !== undefined ? 0 : 1,
        align: 'left',
        lerp: [1, 0.1],
      })

      const refs = useRefs({ target: element })
      const { components } = useApp.components.get()

      const hydration = useHydrate(components).hydrate(refs)

      return { hydration, carousel }
    })
    .filter(Boolean)

  return () => {
    if (__devmode) {
      console.log('carousel teardown start')
    }

    carousels.forEach(({ hydration, carousel }) => {
      hydration.dehydrate()
      carousel.destroy()
    })

    if (__devmode) {
      console.log('carousel teardown finish')
    }
  }
}
