if (history.scrollRestoration) {
  history.scrollRestoration = 'manual'
}

const navigationHistory = []

function getLast() {
  const lastIndex = Math.min(navigationHistory.length - 1, 2)

  return navigationHistory[lastIndex]
}

export default {
  save() {
    navigationHistory[0] = window.scrollY
    navigationHistory.unshift(0)
    navigationHistory.length = Math.min(navigationHistory.length, 3)
  },

  restore({ toTop }) {
    const scrollPos = getLast()

    window.scrollTo({
      top: toTop ? 0 : scrollPos,
      behavior: 'instant',
    })
  },
}
