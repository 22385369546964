import { closeAllMenus, delay } from '../utils'

const fullscreen = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100vw',
}

export default {
  leave({ current }, ref) {
    Object.assign(current.container.style, {
      ...fullscreen,
      top: window.scrollY * -1 + 'px',
    })

    ref.header[0].classList.add('is-transitioning')

    closeAllMenus(ref)
    
    setTimeout(() => {
      closeAllMenus(ref)
    }, 200)
  },
  enter({ current, next }, ref) {
    return new Promise(async resolve => {
      Object.assign(next.container.style, {
        position: 'relative',
        zIndex: 19,
      })

      const { firstElementChild } = next.container
      const opts = { duration: 0.8, ease: 'cubic-bezier(0.65, 0, 0.35, 1)' }
      const leftMenuBackground = ref.overlay[0].querySelector('.overlay-left')

      const hidden = 'polygon(0 0, 0vw 0vh, 0vw 20000vh, 0 20000vh)'
      const visible = 'polygon(0 0, 100vw 0vh, 100vw 20000vh, 0 20000vh)'

      // setup
      next.container.style.clipPath = hidden
      firstElementChild.style.transform = 'translateX(-15vw)'

      await delay(1)

      // animate
      leftMenuBackground.style.transition = `width ${opts.duration}s ${opts.ease}`
      leftMenuBackground.style.width = '100vw'

      next.container.style.transition = `clip-path ${opts.duration}s ${opts.ease}`
      next.container.style.clipPath = visible

      firstElementChild.style.transition = `transform ${opts.duration}s ${opts.ease}`
      firstElementChild.style.transform = 'translateX(0vw)'

      setTimeout(() => {
        // cleanup
        ref.blur[0].classList.add('snap')
        ref.blur[0].classList.remove('active')

        ref.overlay[0].classList.remove('open-left')

        firstElementChild.removeAttribute('style')

        leftMenuBackground.classList.add('snap')
        leftMenuBackground.removeAttribute('style')

        setTimeout(() => {
          ref.header[0].classList.remove('is-transitioning')
          ref.blur[0].classList.remove('snap')
          leftMenuBackground.classList.remove('snap')
        }, 50)

        resolve()
      }, opts.duration * 1000)
    })
  },
}
