export default ref => {
  if (!ref.termsAndConditions) return

  function handleInput(element) {
    if (!ref.termsAndConditionsTarget) return

    ref.termsAndConditionsTarget.forEach(target => {
      target.classList.toggle('disabled', !element.checked)
    })
  }

  ref.termsAndConditions.forEach(element => {
    element.addEventListener('input', () => handleInput(element))
  })

  ref.termsAndConditions.forEach(handleInput)
}
