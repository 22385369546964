import { generateName } from './framework'

/**
 * generateName is just used for the browser to have something to listen to
 * in the code the key is used
 */

export const events = {
  menu: {
    toggled: generateName(),
  },

  blur: {
    hovered: generateName(),
  },

  key: {
    esc: generateName(),
    down: generateName(),
  },

  window: {
    scroll: generateName(),
    navigation: generateName(),
    resize: generateName(),
    // mousemove: generateName(),
  },

  cart: {
    updated: generateName(),
    loading: generateName(),
  },

  product: {
    availableOnline: generateName(),
    variantSelectorUpdate: generateName(),
    hasPreview: generateName(),
  },

  bimbear: {
    initialized: generateName(),
    loaded: generateName(),
    injectProductId: generateName(),
  },

  configurator: {
    optionUpdate: generateName(),
  },
}
