export default ref => {
  if (!ref.banner) return

  ref.banner.forEach(banner => {
    const { width } = banner.getBoundingClientRect()
    const copiesNeeded = Math.ceil(window.innerWidth / width) + 1

    for (let i = 0; i < copiesNeeded; i++) {
      banner.append(banner.firstElementChild.cloneNode(true))
    }

    banner.classList.add('running')
  })
}
