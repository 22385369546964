import { useEvent, useRefs } from './../framework'
import { events } from './../events'

export default (ref, __devmode) => {
  if (!ref.headerColorChange) return

  const TRIGGER_POINT = 35

  let currentColor = null

  function handleColorChange(refs) {
    const activeElement = refs.findLast(element => {
      const { top, bottom } = element.getBoundingClientRect()

      return top < TRIGGER_POINT && bottom > TRIGGER_POINT
    })

    if (activeElement && currentColor !== activeElement.dataset.color) {
      currentColor = activeElement.dataset.color

      const opposite = currentColor === 'white' ? 'washed-black' : 'white'

      ref.header[0].style.setProperty(
        '--header-text-color',
        `var(--${opposite})`
      )

      document.body.style.setProperty(
        '--background-color',
        `var(--${activeElement.dataset.color})`
      )
    } else if (!activeElement) {
      ref.header[0].removeAttribute('style')
      document.body.removeAttribute('style')
      currentColor = null
    }
  }

  function handleScroll() {
    handleColorChange(ref.headerColorChange)
    setTimeout(() => {
      ref.header[0].classList.remove('snap')
    })
  }

  useEvent.listen(events.window.scroll, handleScroll)

  handleScroll()

  /**
   * change/update the color in the shopify preview editor, when a section is updated
   */
  let refsInShopifyPreview = null
  window.addEventListener('shopify:section:load', () => {
    refsInShopifyPreview = useRefs({ asArray: true })
    handleColorChange(refsInShopifyPreview.headerColorChange)
  })

  return () => {
    if (__devmode) {
      console.log('header color change teardown start')
    }

    ref.header[0].removeAttribute('style')
    document.body.removeAttribute('style')
    currentColor = null
    refsInShopifyPreview = null

    if (__devmode) {
      console.log('header color change teardown finish')
    }
  }
}
