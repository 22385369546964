import { events } from "../events"
import { useEvent } from "../framework"


export default ref => {
  if (!ref.mainProductTabs) return

  function updateArrows() {
    ref.mainProductTabs.forEach(nav => {
      const buttons = [...nav.querySelectorAll('button')]

      buttons.forEach(button => {
        const { href } = button.dataset
        const searchParams = new URLSearchParams(window.location.search)

        button.classList.toggle(
          'active',
          (!href && !searchParams.get('tab')) ||
          searchParams.get('tab') === href
        )
      })
    })
  }

  function getHeight() {
    return (
      ref.mainProductTabsContent
        ?.find(tab => tab.classList.contains('active'))
        ?.getBoundingClientRect().height || {}
    )
  }

  function updateContent(setHeight = true) {
    const height = getHeight()

    if (height && setHeight) {
      // ref.mainProductTabsContainer[0].style.overflow = 'hidden'
      ref.mainProductTabsContainer[0].style.transition = 'height 400ms'
      ref.mainProductTabsContainer[0].style.height = height + 'px'
    }

    ref.mainProductTabsContent?.forEach(element => {
      const { id } = element.dataset
      const searchParams = new URLSearchParams(window.location.search)

      element.classList.toggle(
        'active',
        (!id && !searchParams.get('tab')) || searchParams.get('tab') === id
      )
    })

    setTimeout(() => {
      const newHeight = getHeight()
      if (newHeight && setHeight) {
        ref.mainProductTabsContainer[0].style.height = newHeight + 'px'
      }
    })
  }

  function scrollIntoView() {
    ref.mainProductTabsContent.forEach(element => {
      if (element.classList.contains('active')) {
        const { top } = element.getBoundingClientRect()
        window.scrollTo({ top: window.scrollY + top - 100, behavior: 'smooth' })
      }
    })
  }

  updateArrows()
  updateContent(false)

  ref.mainProductTabs.forEach(nav => {
    const buttons = [...nav.querySelectorAll('button')]

    buttons.forEach(button => {
      button.addEventListener('click', () => {
        const { href = '' } = button.dataset
        const url = new URL(window.location)

        if (!href) {
          url.searchParams.delete('tab')
        } else {
          url.searchParams.set('tab', href)
        }

        window.history.replaceState(null, '', url.toString())

        updateArrows()
        updateContent()
        // scrollIntoView()
      })
    })
  })

  useEvent.listen(events.window.resize, () => {
    ref.mainProductTabsContainer[0].style.removeProperty('height')
    ref.mainProductTabsContainer[0].style.removeProperty('overflow')
    ref.mainProductTabsContainer[0].style.removeProperty('transition')
  })
}
