export default ({ el, children = 'li', speed = 3000, shouldRun = true }) => {
  const initialDOM = el.innerHTML
  const initialChildren = el.querySelectorAll(children)

  let interval = null
  let currentIndex = 0
  let elements = {}
  let startX = 0
  let endX = 0

  function init() {
    elements = setupElements(el, children)
    interval = setInterval(update, speed)
    prepare(elements, currentIndex)
    el.classList.remove('destroyed')
    addTouchListeners(el)
  }

  function destroy() {
    clearInterval(interval)
    el.innerHTML = initialDOM
    el.classList.add('destroyed')
  }

  function update(inc = 1) {
    if (document.hidden) return

    if (inc < 0 && currentIndex === 0) currentIndex = elements.length - 1
    else if (inc > 0 && currentIndex === elements.length - 1) currentIndex = 0
    else currentIndex = (currentIndex + inc) % elements.length

    prepare(elements, currentIndex)
  }

  function addTouchListeners(el) {
    el.addEventListener('touchstart', handleTouchStart, false)
    el.addEventListener('touchmove', handleTouchMove, false)
    el.addEventListener('touchend', handleTouchEnd, false)
  }

  function handleTouchStart(event) {
    const touch = event.touches[0]
    startX = touch.clientX
  }

  function handleTouchMove(event) {
    const touch = event.touches[0]
    endX = touch.clientX
  }

  function handleTouchEnd() {
    const deltaX = endX - startX
    if (deltaX > 50) {
      update(1)
    } else if (deltaX < -50) {
      update(1)
    }
  }

  if (!shouldRun) {
    destroy()
  } else {
    init()
  }

  return {
    el,
    initialChildren,
    elements,
    init,
    destroy: delay => {
      if (!delay) {
        destroy()
      } else {
        setTimeout(destroy, delay)
      }
    },
  }
}

function prepare(elements, currentIndex) {
  elements.items.forEach((item, idx) => {
    const left = count(currentIndex, -1 + item.index, item.images.length)
    const active = count(currentIndex, 0 + item.index, item.images.length)
    const right = count(currentIndex, 1 + item.index, item.images.length)

    item.images.forEach(image => {
      image.element.style.transition = `none`
      image.element.style.transform = `translateX(100%)`
      image.element.style.zIndex = 0

      if (image.index === active) {
        image.element.style.transition = `transform 0.5s ease`
        image.element.style.transform = `translateX(0%)`
        image.element.style.zIndex = 2
      } else if (image.index === left) {
        image.element.style.transition = `transform 0.5s ease`
        image.element.style.transform = `translateX(-100%)`
        image.element.style.zIndex = 0
      } else if (image.index === right) {
        image.element.style.transition = `transform 0.5s ease`
        image.element.style.transform = `translateX(100%)`
        image.element.style.zIndex = 0
      }
    })
  })
}

function setupElements(el, children) {
  const images = [...el.querySelectorAll(children)]

  images.forEach(item => {
    item.remove()
  })

  const makeItem = i => {
    const li = document.createElement('li')
    const div = document.createElement('div')

    li.dataset.index = i

    const imgs = images.map((item, idx) => {
      const img = item.querySelector('img')
      img.dataset.index = idx
      const imageClone = img.cloneNode(true)
      div.append(imageClone)

      return {
        element: imageClone,
        index: idx,
      }
    })

    li.append(div)

    return {
      element: li,
      index: i,
      images: imgs,
    }
  }

  const items = [...Array(5)].map((_, i) => {
    const item = makeItem(i)
    el.append(item.element)

    return item
  })

  return {
    parent: el,
    length: images.length,
    items,
  }
}

/**
 * "counts" the indexes in an array, loops when reaching either end
 */
function count(fromIdx, distance, len) {
  if (distance === 0) return fromIdx
  let i = 0
  let index = fromIdx

  if (distance < 0) {
    while (i > distance) {
      if (index === 0) index = len - 1
      else index--
      i--
    }
  }

  if (distance > 0) {
    while (i < distance) {
      if (index === len - 1) index = 0
      else index++
      i++
    }
  }

  return index
}

