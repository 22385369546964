import { useEvent } from '../framework'
import { events } from '../events'

export default ref => {
  if (!ref.scrollIndicator) return

  const isHomepage = () => window.location.pathname === '/'

  function showHide() {
    ref.scrollIndicator.forEach(item => {
      setTimeout(() => {
        item.classList.toggle('active', isHomepage())
      })
    })
  }

  function parallax() {
    setTimeout(() => {
      ref.scrollIndicator.forEach(item => {
        if (!isHomepage() || window.scrollY > window.innerHeight) {
          return
        }

        item.style.transform = `translateY(${window.scrollY * -0.1}px)`
        const opacity = 1 - window.scrollY / 100

        if (opacity >= 0 && opacity <= 1) {
          item.style.opacity = opacity
        } else {
          item.style.opacity = 0
        }
      })
    })
  }

  showHide()
  parallax()

  useEvent.listen(events.window.navigation, showHide, { global: true })
  useEvent.listen(events.window.scroll, parallax, { global: true })
}
