export default (ref, __devmode) => {
  if (!ref.inView) return

  const callback = (entries, observer) => {
    entries.forEach(entry => {
      entry.target.classList.toggle('in-view', entry.isIntersecting)

      if (entry.isIntersecting) {
        entry.target.classList.add('is-seen')
      }

      if (entry.isIntersecting && entry.target.dataset.once) {
        observer.unobserve(entry.target)
      }
    })
  }

  const observer = new IntersectionObserver(callback, {
    rootMargin: '-200px 0px -200px 0px',
  })

  ref.inView.forEach(element => observer.observe(element))

  return () => {
    if (__devmode) {
      console.log('in-view teardown start')
    }

    observer.disconnect()

    if (__devmode) {
      console.log('in-view teardown finish')
    }
  }
}
