// page components (re-hydrate on page shift)
import accordionItem from './accordion-item'
import activateLinks from './activate-links'
import carousel from './carousel'
import cartLink from './cart-link'
// import cylindoCustomViewer from './cylindo-custom-viewer'
// import bimbearCustomViewer from './bimbear-custom-viewer'
import headerColorChange from './header-color-change'
import gallery from './gallery'
import inView from './in-view'
import mainCollection from './main-collection'
import marquee from './marquee'
import menuMarquee from './menu-marquee'
import predictiveSearch from './predictive-search'
import profileCardCarousel from './profile-card-carousel'
import setTransition from './set-transition'
import dropupMenu from './dropup-menu'
import banner from './banner'
import showroom from './showroom'
import cart from './cart'
import parallax from './parallax'
import featuredProducts from './featured-products'
import mainProductTabs from './main-product-tabs'
import variantSelectorAvailableOnline from './variant-selector-available-online'
// import mainProductCylindoViewer from './main-product-cylindo-viewer'
import mainProductBimbearViewer from './main-product-bimbear-viewer'
import mainProductHasPreviewLabel from './main-product-has-preview-label'
import addToCart from './add-to-cart'
import termsAndConditions from './terms-and-conditions'
import giftCard from './gift-card'
import variantSelectorNew from './variant-selector'
import clearButton from './clear-button'
import newsletter from './newsletter'
import readMore from './read-more'
import mainProductThumbnails from './main-product-thumbnails'
import downloadable from './downloadable'
import fadingCarousel from './fading-carousel'
// import fakeSticky from './fake-sticky'
import imageLoad from './image-load'
import customCursor from './custom-cursor'

export const components = [
  accordionItem,
  activateLinks,
  addToCart,
  banner,
  carousel,
  cart,
  cartLink,
  // cylindoCustomViewer,
  // bimbearCustomViewer,
  dropupMenu,
  featuredProducts,
  headerColorChange,
  gallery,
  inView,
  mainCollection,
  mainProductTabs,
  // mainProductCylindoViewer,
  mainProductBimbearViewer,
  mainProductHasPreviewLabel,
  marquee,
  menuMarquee,
  parallax,
  predictiveSearch,
  profileCardCarousel,
  setTransition,
  showroom,
  variantSelectorAvailableOnline,
  termsAndConditions,
  giftCard,
  variantSelectorNew,
  clearButton,
  newsletter,
  readMore,
  mainProductThumbnails,
  downloadable,
  fadingCarousel,
  // fakeSticky,
  imageLoad,
  customCursor,
]

// global components (don't re-hydrate on page shift)
// generally the elements/components thats outside of the <main>
import blur from './blur'
import globalEvents from './global-events'
import header from './header'
import menuLeft from './menu-left'
import menuMobile from './menu-mobile'
import menuRight from './menu-right'
import overlay from './overlay'
import scrollIndicator from './scroll-indicator'

export const globals = [
  blur,
  globalEvents,
  header,
  menuLeft,
  menuMobile,
  menuRight,
  overlay,
  scrollIndicator,
]
