export default ref => {
  if (!ref.clearButton) return

  ref.clearButton.forEach(button => {
    button.addEventListener('click', () => {
      const { clear } = button.dataset
      const target = document.body.querySelector(clear)
      
      target.value = ''
      target.focus()
    })
  })
}
