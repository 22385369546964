import { closeAllMenus, delay } from '../utils'

const fullscreen = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
}

export default {
  leave({ current }, ref) {
    closeAllMenus(ref)

    Object.assign(current.container.style, {
      ...fullscreen,
      top: window.scrollY * -1 + 'px',
    })

    ref.header[0].classList.add('is-transitioning')

    return Promise.resolve()
  },
  enter({ current, next }, ref) {
    return new Promise(async resolve => {
      Object.assign(next.container.style, { position: 'relative', zIndex: 19 })

      const opts = { duration: 0.8, ease: 'cubic-bezier(0.65, 0, 0.35, 1)' }

      const hidden = 'polygon(100vw 0, 100vw 0vh, 100vw 200vh, 100vw 200vh)'
      const visible = 'polygon(0 0, 100vw 0vh, 100vw 200vh, 0 200vh)'
      const { firstElementChild } = next.container
      const rightMenuBackground = ref.overlay[0].querySelector('.overlay-right')

      // setup
      next.container.style.clipPath = hidden
      firstElementChild.style.transform = 'translateX(15vw)'

      await delay(1)

      // animate
      rightMenuBackground.style.transition = `width ${opts.duration}s ${opts.ease}`
      rightMenuBackground.style.width = '100vw'

      next.container.style.transition = `clip-path ${opts.duration}s ${opts.ease}`
      next.container.style.clipPath = visible

      firstElementChild.style.transition = `transform ${opts.duration}s ${opts.ease}`
      firstElementChild.style.transform = 'translate(0vw)'

      setTimeout(() => {
        // cleanup
        ref.blur[0].classList.add('snap')
        ref.blur[0].classList.remove('active')

        ref.overlay[0].classList.remove('open-right')

        firstElementChild.removeAttribute('style')
        
        rightMenuBackground.classList.add('snap')
        rightMenuBackground.removeAttribute('style')

        setTimeout(() => {
          ref.header[0].classList.remove('is-transitioning')
          ref.blur[0].classList.remove('snap')
          rightMenuBackground.classList.remove('snap')
        }, 50)

        resolve()
      }, opts.duration * 1000)
    })
  },
}
