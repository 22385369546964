import { useRefs, useEvent } from './../framework'
import { events } from './../events'
import { toggleBurgerIcon } from '../utils'

export default ref => {
  if (!ref.mobileMenu) return

  let refs = useRefs({ target: ref.mobileMenu[0], asArray: true })

  function closeMenu() {
    refs.root.classList.remove('open')

    toggleBurgerIcon(refs.trigger[0], false)

    useEvent.dispatch(events.menu.toggled, {
      menu: 'mobile',
      open: false,
    })
  }

  useEvent.listen(events.window.resize, closeMenu, { global: true })

  // open close the menu
  refs.trigger[0].addEventListener('click', () => {
    refs.root.classList.toggle('open')

    toggleBurgerIcon(refs.trigger[0], refs.root.classList.contains('open'))

    useEvent.dispatch(events.menu.toggled, {
      menu: 'mobile',
      open: refs.root.classList.contains('open'),
    })
  })

  // open close the lists
  refs.triggers?.forEach(trigger => {
    trigger.addEventListener('click', async () => {
      trigger.classList.toggle('open')

      const opening = trigger.classList.contains('open')
      const list = trigger.parentElement.querySelector('.animate-target')

      if (opening) {
        list.classList.add('open')
        const { height } = getComputedStyle(list)

        list.style.height = '0px'

        setTimeout(() => {
          list.style.transition = 'height 300ms ease-in-out'
          list.style.height = height
          
          setTimeout(() => list.style.removeProperty('height'), 300)
        })
      } else {
        const { height } = getComputedStyle(list)
        list.classList.remove('open')

        list.style.height = height

        setTimeout(() => {
          list.style.transition = 'height 300ms ease-in-out'
          list.style.height = '0px'
          
          setTimeout(() => list.style.removeProperty('height'), 300)
        })
      }
    })
  })

  return () => (refs = null)
}
