import { closeAllMenus, delay } from '../utils'
import { breakpoints } from './../breakpoints'

const fullscreen = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
}

export default {
  leave({ current }, ref) {
    closeAllMenus(ref, true)

    Object.assign(current.container.style, {
      ...fullscreen,
      top: window.scrollY * -1 + 'px',
      zIndex: 9,
    })
  },
  enter({ current, next }, ref) {
    return new Promise(async resolve => {
      next.container.style.position = 'relative'

      const visible =
        'polygon(0vw 0vh, 100vw 0vh, 100vw 200000vh, 0vw 200000vh)'

      current.container.style.clipPath = visible
      next.container.style.transform = 'translateX(-15vw)'

      const opts = {
        duration: window.innerWidth < breakpoints.md ? 0.4 : 0.6,
        ease: 'cubic-bezier(0.65, 0, 0.35, 1)',
      }

      const { firstElementChild } = current.container

      const hidden =
        'polygon(100vw 0vh, 100vw 0vh, 100vw 200000vh, 100vw 200000vh)'

      await delay(1)

      current.container.style.transition = `clip-path ${opts.duration}s ${opts.ease}`
      current.container.style.clipPath = hidden

      firstElementChild.style.transition = `transform ${opts.duration}s ${opts.ease}`
      firstElementChild.style.transform = `translateX(50vw)`

      next.container.style.transition = `transform ${opts.duration}s ${opts.ease}`
      next.container.style.transform = `translateX(0vw)`

      setTimeout(resolve, opts.duration * 1000)
    })
  },
}
