export default ref => {
  if (!ref.readMore) return

  ref.readMore.forEach(element => {
    if (element.dataset.hydrated !== undefined) return

    const trigger = element.querySelector('[data-ref="read-more-trigger"]')
    const target = element.querySelector('[data-ref="read-more-target"]')

    element.dataset.hydrated = ''

    if (!trigger || !target) return

    function handleOpen() {
      trigger.style.opacity = 0

      const { height: fromHeight } = target.getBoundingClientRect()

      target.style.height = 'auto'

      setTimeout(() => {
        const { height: toHeight } = target.getBoundingClientRect()

        target.style.transition = 'height 300ms ease-in-out'
        target.style.height = fromHeight + 'px'

        setTimeout(() => {
          target.style.height = toHeight + 'px'

          setTimeout(() => {
            target.style.height = 'auto'
            target.classList.add('open')
          }, 300)
        }, 100)
      })
    }

    trigger.removeEventListener('click', handleOpen)
    trigger.addEventListener('click', handleOpen)
  })
}
