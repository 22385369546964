import { delay, closeAllMenus } from '../utils'

const fullscreen = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  zIndex: 1,
}

export default {
  leave({ current }, ref) {
    closeAllMenus(ref, true)

    Object.assign(current.container.style, {
      ...fullscreen,
      top: window.scrollY * -1 + 'px',
      zIndex: 9,
    })

    ref.header[0].classList.add('is-transitioning')
  },
  enter({ current, next, trigger }, ref) {
    return new Promise(async resolve => {

      next.container.style.position = 'relative'
      // next.container.style.zIndex = 10

      const media = trigger.querySelector('.product-card-media')
      const imageCaption = media.querySelector('.image-caption')
      imageCaption.remove()

      const placeholder = document.createElement('div')
      const originalPosition = media?.getBoundingClientRect() || {}

      const style = {
        top: originalPosition.top + 'px',
        left: originalPosition.left + 'px',
        width: originalPosition.width + 'px',
        height: originalPosition.height + 'px',
      }

      Object.assign(placeholder.style, style)
      Object.assign(media.style, {
        ...style,
        position: 'fixed',
        zIndex: 20,
        mixBlendMode: 'darken',
      })

      trigger.insertAdjacentElement('afterbegin', placeholder)

      document.body.append(media)

      const target = next.container.querySelector(
        '.main-product-featured-image'
      )

      if (target) target.style.opacity = 0

      const opts = { duration: 0.8, ease: 'cubic-bezier(0.65, 0, 0.35, 1)' }

      const targetPosition = target?.getBoundingClientRect()

      current.container.style.transition = `opacity ${opts.duration}s ${opts.ease}`
      current.container.style.opacity = 0

      Object.assign(media.style, {
        transition: `all ${opts.duration}s ${opts.ease}`,
        ...(target
          ? {
              top: targetPosition.top + 'px',
              left: targetPosition.left + 'px',
              width: targetPosition.width + 'px',
              height: targetPosition.height + 'px',
            }
          : { scale: 0 }),
      })

      setTimeout(() => {
        if (target) target.style.opacity = 1

        ref.header[0].classList.remove('is-transitioning')
        media.remove()
        resolve()
      }, opts.duration * 1000)
    })
  },
}
