export default ref => {
  if (!ref.downloadable) return

  ref.downloadable.forEach(element => {
    const { hrefs } = element.dataset

    const fileUrls = hrefs.split(';;;').filter(Boolean)

    const getFilename = pathname => pathname.split('\\').pop().split('/').pop()

    function download(url, filename) {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = filename
          link.click()
        })
        .catch(console.error)
    }

    function handleClick() {
      fileUrls.forEach(url => {
        download(url, getFilename(url.split('?')[0]))
      })
    }

    element.addEventListener('click', handleClick)
  })
}
