import { useEvent } from '../framework'
import { events } from './../events'

export default ref => {
  if (!ref.overlay) return

  function toggleOverlay(open, side) {
    ref.overlay[0].classList.remove('open-left', 'open-right', 'open-mobile')

    if (open) {
      ref.overlay[0].classList.add('open-' + side)
    }
  }

  useEvent.listen(
    events.menu.toggled,
    ({ detail }) => toggleOverlay(detail.open, detail.menu),
    { global: true }
  )

  useEvent.listen(events.key.esc, () => toggleOverlay(false), { global: true })
}
