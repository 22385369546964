import { useEvent } from './../framework'
import { events } from './../events'

export default ref => {
  if (!ref.blur) return

  ref.blur[0].addEventListener('mousemove', () => {
    if (!ref.blur[0].classList.contains('active')) return

    useEvent.dispatch(events.blur.hovered, true)
  })

  useEvent.listen(
    events.menu.toggled,
    ({ detail }) => {
      ref.blur[0].classList.toggle('active', detail.open)
    },
    { global: true }
  )

  useEvent.listen(
    events.key.esc,
    () => ref.blur[0].classList.remove('active'),
    { global: true }
  )
}
