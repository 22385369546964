import { useRefs } from '../framework'

const moveLogo = (root, paths, run) => {
  return new Promise(resolve => {
    if (!run) {
      root.remove()
      return resolve()
    }

    // setup
    paths.forEach(path => (path.style.transform = `translateY(100%)`))

    setTimeout(() => {
      const amount = paths.length
      const delay = 50

      paths.forEach((path, idx) => {
        path.style.transition = `transform 700ms ${idx * delay}ms`
        path.style.transform = `translateY(0%)`

        setTimeout(() => {
          path.closest('.page-load-animation').style.transition =
            'opacity 1000ms'
          path.closest('.page-load-animation').style.opacity = 0

          setTimeout(() => {
            resolve()
            root.remove()
          }, 500)
        }, 700 + amount * delay)
      })
    })
  })
}

export default async (data, ref) => {
  const DURATION = 1000
  const DELAY = sessionStorage.getItem('is_loaded') ? 1000 : 2000

  const refs = useRefs({ target: ref.pageLoadAnimation[0] })

  const paths = [...refs.logo.querySelectorAll('path')]

  /**
   * setup
   */
  ref.leftMenu[0].style.overflow = 'hidden'
  ref.leftMenu[0].firstElementChild.style.transition = `transform ${DURATION}ms`
  ref.leftMenu[0].firstElementChild.style.transform = 'translateY(100%)'

  ref.rightMenu[0].style.overflow = 'hidden'
  ;[...ref.rightMenu[0].children].forEach((child, idx) => {
    child.style.transition = `transform ${DURATION}ms ${100 + 100 * idx}ms`
    child.style.transform = 'translateY(100%)'
  })

  const shouldRun = !sessionStorage.getItem('is_loaded')

  await moveLogo(refs.root, paths, shouldRun)

  /**
   * action!
   */
  setTimeout(() => {
    ref.header[0].classList.remove('snap')
    
    ref.leftMenu[0].firstElementChild.style.transform = 'translateY(0%)'
    ;[...ref.rightMenu[0].children].forEach((child, idx) => {
      child.style.transform = 'translateY(0%)'
    })

    setTimeout(() => {
      /**
       * cleanup
       */
      ref.leftMenu[0].style.removeProperty('overflow')
      ref.leftMenu[0].firstElementChild.style.removeProperty('transition')
      ref.leftMenu[0].firstElementChild.style.removeProperty('transform')

      ref.rightMenu[0].style.removeProperty('overflow')
      ;[...ref.rightMenu[0].children].forEach(child => {
        child.style.removeProperty('transition')
        child.style.removeProperty('transform')
      })
    }, DURATION)
  })

  sessionStorage.setItem('is_loaded', true)

  document.body.classList.add('loaded')
}
