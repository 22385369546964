import { useRefs, useApp, useHydrate } from '../framework'
import { zeroPad } from '../utils'
import DragScroll from './drag-scroll'

export default ref => {
  if (!ref.profileCardCarousel) return

  const instances = ref.profileCardCarousel
    .map(element => {
      if (!element.children.length) return

      const carouselElement = element.querySelector('.ul')

      const prevButton = element.querySelector('.previous')
      const nextButton = element.querySelector('.next')
      const current = element.querySelector('.current')

      const carousel = DragScroll({
        el: carouselElement,
        item: '.li',
        loop: true,
        snap: true,
        align: 'left',
        lerp: [0.1, 0.1],
        onUpdate(state, atPos) {
          current.textContent = zeroPad(atPos + 1)
        },
      })

      prevButton?.addEventListener('click', () => carousel.navigate(-1))
      nextButton?.addEventListener('click', () => carousel.navigate(1))

      const refs = useRefs({ target: element, asArray: true })
      const { components } = useApp.components.get()

      useHydrate(components).hydrate(refs)

      return carousel
    })
    .filter(Boolean)

  return () => {
    instances.forEach(instance => {
      instance.destroy()
    })
  }
}
