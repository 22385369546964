import { isMobile } from '../utils'

export default ref => {
  if (!ref.image) return
  if (isMobile()) return
  if (!document.documentElement.classList.contains('js')) return

  function callback(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.remove('not-loaded')
        entry.target.classList.add('loaded')
        observer.unobserve(entry.target)

        setTimeout(() => {
          entry.target.classList.remove('loaded')
        }, 1200)
      }
    })
  }

  const observer = new IntersectionObserver(callback)

  ref.image.forEach(image => {
    image.classList.add('not-loaded')
    observer.observe(image)
  })
}
