import barba from '@barba/core'

import {
  useApp,
  useEvent,
  useHydrate,
  useRefs,
  useTransition,
} from '../framework'
import transitions from '../transitions'
import { globals, components } from '../components'
import { events } from '../events'
import vocast from '../vocast'

vocast()
const __devmode = location.hostname.includes('127.0.0.1')

// save reference to all components somewhere,
// to allow components to hydrate each other
// good for components that add new markup
useApp.components.set({ components, globals })

const { globals: globalComponents, components: pageComponents } =
  useApp.components.get()

let refs = useRefs({ asArray: true })

// hydrate global components once
useHydrate(globalComponents).hydrate(refs)

// hydrate the page's components, save reference to them for later re-hydration
const hydration = useHydrate(pageComponents).hydrate(refs)

const ENABLE_BARBA = true

if (
  ENABLE_BARBA &&
  !document.documentElement.classList.contains('shopify-design-mode')
) {
  barba.init({
    timeout: 5000,
    debug: __devmode,
    transitions: useTransition({
      refs,
      transitions,
      globals: {
        // runs on initial page load
        once(data) {
          transitions.pageLoad(data, refs)
        },

        // runs before every page shift
        leave() {
          refs = null
          useEvent.dispatch(events.window.navigation)
          useEvent.dehydrate()
        },

        // runs after every page shift
        enter({ current }) {
          current.container.classList.add('old-page')

          refs = useRefs({ exclude: '.old-page', asArray: true })
          hydration.hydrate(refs, __devmode)
          vocast()
        },
      },
    }),
  })
} else {
  transitions.pageLoad('', refs)
}

// logs all currently active event listeners
window.allListeners = () => {
  return Array.from(document.querySelectorAll('*')).reduce(function (pre, dom) {
    var evtObj = getEventListeners(dom)
    Object.keys(evtObj).forEach(function (evt) {
      if (typeof pre[evt] === 'undefined') {
        pre[evt] = { dom: [], count: 0 }
      }
      pre[evt].dom.push(dom)
      pre[evt].count += evtObj[evt].length
    })
    return pre
  }, {})
}
