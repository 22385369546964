window.norr11App = window.norr11App || {}

// just a quick way of storing some global variables

export default {
  components: {
    set({ globals, components }) {
      window.norr11App.components = { globals, components }
    },
    get() {
      return window.norr11App.components
    },
  },
  transition: {
    set(name, payload) {
      window.norr11App.transition = { name, payload }
    },
    get() {
      return window.norr11App.transition
    },
  },
}
