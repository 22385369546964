import { useEvent, useRefs } from '../framework'
import { scrollLock } from './scroll-lock'
import { debounce } from '../utils'
import { events } from '../events'

export default ref => {
  if (!ref.dropup) return

  ref.dropup.forEach(dropup => {
    const refs = useRefs({ target: dropup })

    let isScrolling = false

    const scrollend = debounce(() => {
      isScrolling = false

      if (!refs.root.classList.contains('open')) return

      const { top, height } = refs.content.getBoundingClientRect()

      const triggerPoint = Math.ceil(
        Math.max(window.innerHeight - height, window.innerHeight * 0.25)
      )

      if (top > triggerPoint && top < triggerPoint + 100) {
        handleOpen()
        return
      }

      if (top > triggerPoint) {
        handleClose()
      }
    }, 100)

    function handleOpen() {
      isScrolling = true

      const { height } = refs.content.getBoundingClientRect()

      scrollLock(true)
      refs.root.classList.add('open')
      refs.popup.scrollTo({
        behavior: 'smooth',
        top: Math.min(height, window.innerHeight * 0.75),
      })
    }

    function handleClose() {
      if (isScrolling) return

      scrollLock(false)
      refs.root.classList.remove('open')
      // refs.popup.scrollTo({ behavior: 'smooth', top: 0 })
      refs.popup.scrollTo({ behavior: 'instant', top: 0 })
    }

    function handleScroll(e) {
      scrollend()
    }

    function handleOutsideClick(e) {
      const { clientY } = e
      const { top } = refs.content.getBoundingClientRect()

      if (!isScrolling && top > clientY) {
        handleClose()
      }
    }

    refs.opener.addEventListener('click', handleOpen)
    refs.closer.addEventListener('click', handleClose)
    refs.popup.addEventListener('scroll', handleScroll)
    refs.popup.addEventListener('click', handleOutsideClick)

    useEvent.listen(events.product.variantSelectorUpdate, handleClose)
  })
}
