import { closeAllMenus, delay } from '../utils'

const fullscreen = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  minHeight: '100vh',
}

let scrollPos = 0
let params = ''

export default {
  before() {
    params = norr11App.transition.payload
  },
  leave({ current }, ref) {
    closeAllMenus(ref, true)

    scrollPos = window.scrollY

    Object.assign(current.container.style, {
      ...fullscreen,
      top: window.scrollY * -1 + 'px',
      zIndex: 9,
    })
  },
  enter({ next }) {
    return new Promise(async resolve => {
      next.container.style.position = 'relative'

      window.scrollTo({ top: scrollPos })
      const url = new URL(window.location.href + params)
      url.searchParams.set('instant', true) // used in main-product-cylindo-viewer

      window.history.replaceState({}, null, url.toString())

      setTimeout(resolve, 1)
    })
  },
}
