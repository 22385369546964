import { breakpoints } from './breakpoints'

/**
 * checks if the current viewport is small (mobile)
 */
export const isMobile = () => window.innerWidth < breakpoints.md

/**
 * wraps a function that will be debounced
 */
export const debounce = (func, wait, immediate) => {
  let timeout

  return function () {
    let context = this
    const args = arguments

    const callNow = immediate && !timeout

    clearTimeout(timeout)

    timeout = setTimeout(function () {
      timeout = null

      if (!immediate) {
        func.apply(context, args)
      }
    }, wait)

    if (callNow) func.apply(context, args)
  }
}

/**
 * helper to close all menus in the header
 */
export const closeAllMenus = (ref, andOverlay) => {
  const leftMenus = [
    ref.leftMenu[0],
    ...ref.leftMenu[0].querySelectorAll('.open'),
  ]

  const rightMenus = [
    ref.rightMenu[0],
    ...ref.rightMenu[0].querySelectorAll('.open'),
  ]

  const menus = [ref.header[0], ...leftMenus, ref.mobileMenu[0], ...rightMenus]
  const mobileMenus = [...ref.mobileMenu[0].querySelectorAll('.open')]

  menus.forEach(menu => menu.classList.remove('open'))
  ref.header[0].classList.remove('right', 'left')

  if (andOverlay) {
    ref.overlay[0].classList.remove('open-mobile', 'open-left', 'open-right')
    ref.blur[0].classList.remove('active')
    ref.header[0].classList.remove('open')
  }

  setTimeout(() => {
    mobileMenus.forEach(menu => menu.classList.remove('open'))
  }, 600)
}

/**
 * like a settimeout without the nesting
 */
export const delay = (ms = 0) => new Promise(resolve => setTimeout(resolve, ms))

/**
 * changes the snippets/burger-icon.liquid from burger to cross
 */
export const toggleBurgerIcon = (container, state) => {
  container.querySelector('.burger-icon')?.classList.toggle('open', state)
}

/**
 * rounds a number..
 */
export const round = (number, decimals = 2) =>
  Math.round(number * 10 ** decimals) / 10 ** decimals

export const clamp = (min, val, max) => Math.min(max, Math.max(min, val))

/*
 * taken from https://gist.github.com/gre/1650294
 * only considering the t value for the range [0, 1] => [0, 1]
 */

export const linear = t => t
export const easeInQuad = t => t * t
export const easeOutQuad = t => t * (2 - t)
export const easeInOutQuad = t => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t)
export const easeInCubic = t => t * t * t
export const easeOutCubic = t => --t * t * t + 1
export const easeInOutCubic = t =>
  t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
export const easeInQuart = t => t * t * t * t
export const easeOutQuart = t => 1 - --t * t * t * t
export const easeInOutQuart = t =>
  t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t
export const easeInQuint = t => t * t * t * t * t
export const easeOutQuint = t => 1 + --t * t * t * t * t
export const easeInOutQuint = t =>
  t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t

// 1 -> 01
export const zeroPad = x => x.toString().padStart(2, '0')
