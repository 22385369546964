import { useEvent } from '../framework'
import { events } from '../events'
import { debounce } from '../utils'

export default () => {
  window.addEventListener('keydown', e => {
    useEvent.dispatch(events.key.down, e)

    if (e.key === 'Escape') {
      useEvent.dispatch(events.key.esc, e)
    }
  })

  window.addEventListener(
    'scroll',
    e => {
      useEvent.dispatch(events.window.scroll, e)
    },
    { passive: true }
  )

  let innerWidth = window.innerWidth
  let innerHeight = window.innerHeight

  window.addEventListener(
    'resize',
    debounce(e => {
      useEvent.dispatch(events.window.resize, {
        deltaWidth: window.innerWidth - innerWidth,
        deltaHeight: window.innerHeight - innerHeight,
      })

      innerWidth = window.innerWidth
      innerHeight = window.innerHeight
    }, 1000)
  )

  // window.addEventListener('mousemove', e =>
  //   useEvent.dispatch(events.window.mousemove, e)
  // )
}
