import { useEvent } from '../framework'
import { events } from '../events'

export const scrollLock = lock => {
  if (lock) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.removeProperty('overflow')
  }

  useEvent.listen(events.window.resize, ({ deltaWidth }) => {
    if (deltaWidth !== 0) scrollLock(false)
  })
}
