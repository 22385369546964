import { useRefs, useEvent } from '../framework'
import { events } from './../events'

export default ref => {
  if (!ref.rightMenu) return

  let refs = useRefs({ target: ref.rightMenu[0], asArray: true })
  const rightMenuTeaser = refs.root.querySelectorAll('.right-menu-teaser')
  rightMenuTeaser.forEach(teaser => {
    const imageWrapper = teaser.querySelector('.image-wrapper')
    const arrowLink = teaser.querySelector('.arrow-link')
    imageWrapper?.prepend(arrowLink)

  })

  function closeAll({ except } = {}) {
    if (!except) {
      refs.root?.classList.remove('open')
    }

    refs.triggers?.map(trigger => {
      if (trigger === except) return

      trigger.parentElement.querySelector('ul')?.classList.remove('open')
    })
  }

  refs.triggers?.map(trigger => {
    trigger.addEventListener('mousemove', () => {
      if (ref.header[0].classList.contains('is-transitioning')) return

      closeAll({ except: trigger })

      trigger.parentElement.querySelector('ul')?.classList.add('open')

      useEvent.dispatch(events.menu.toggled, { menu: 'right', open: true })
      refs.root.classList.add('open')
    })
  })

  function handleMenuToggle({ detail }) {
    if (detail.menu === 'right') return

    if (detail.open) {
      closeAll()
    }
  }

  useEvent.listen(events.blur.hovered, closeAll, { global: true })
  useEvent.listen(events.menu.toggled, handleMenuToggle, { global: true })

  return () => (refs = null)
}
