export default ref => {
  if (!ref.accordionItem) return

  function toggleAccordion(clickedIdx) {
    ref.accordionItem.forEach(async (item, idx) => {
      const content = item.querySelector('.accordion-item-content')
      const contentInner = content.querySelector('.rich-text')
      const opening = clickedIdx === idx && !item.classList.contains('open')
      const button = item.querySelector('.accordion-item-trigger')
      const arrow = button.querySelector('svg')
      
      if (opening) {
        console.log(ref.accordionItem, clickedIdx)

        item.classList.add('open')
        const { height } = getComputedStyle(content)
        arrow.style.transform = 'rotate(180deg)'

        content.style.height = '0px'
        content.style.transition = 'height 300ms ease-in-out'

        setTimeout(() => {
          content.style.height = height

          setTimeout(() => content.style.removeProperty('height'), 300)
        })
      } else {
        // if (!contentInner) return

        const { height } = getComputedStyle(content)
        item.classList.remove('open')
        arrow.style.transform = 'rotate(0deg)'

        console.log(height)

        content.style.height = height
        content.style.transition = 'height 300ms ease-in-out'

        setTimeout(() => {
          content.style.height = '0px'

          setTimeout(() => content.style.removeProperty('height'), 300)
        })
      }
    })
  }

  ref.accordionItem.forEach((item, idx) => {
    const trigger = item.querySelector('button')

    trigger.addEventListener('click', () => toggleAccordion(idx))
  })
}
