import { events } from '../events'
import { useEvent } from '../framework'

export default ref => {
  if (!ref.variantSelectorAvailableOnline) return

  function toggleLabel(visible) {
    ref.variantSelectorAvailableOnlineLabel?.forEach(element => {
      element.classList.toggle('visually-hidden', !visible)
    })
  }

  function updateUrl(checkbox) {
    const url = new URL(window.location)

    if (!checkbox.checked) {
      url.searchParams.delete('available', 'true')
    } else {
      url.searchParams.set('available', 'true')
    }

    toggleLabel(checkbox.checked)

    window.history.replaceState(null, '', url.toString())

    useEvent.dispatch(events.product.availableOnline)
  }

  ref.variantSelectorAvailableOnline?.forEach(element => {
    const url = new URL(window.location)
    element.checked = url.searchParams.has('available')

    element.addEventListener('input', () => updateUrl(element))

    updateUrl(element)
  })
}
