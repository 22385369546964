import { events } from '../events'
import { useEvent } from '../framework'
import { breakpoints } from '../breakpoints'

/**
 * main-collection
 * 
 * re-order items to fill up the grid,
 * given that an item can be 1, 2 or 3 columns wide
 */

export default ref => {
  if (!ref.mainCollection) return

  // save original position in grid
  ref.mainCollection.forEach(element => {
    const items = [...element.querySelectorAll('[data-size]')]
    items.forEach((item, idx) => (item.dataset.idx = idx))
  })

  function sortByIdx(itemA, itemB) {
    return parseInt(itemA.dataset.idx) < parseInt(itemB.dataset.idx) ? -1 : 1
  }

  function sortIntoEvenGrid(items, columns) {
    const newRow = item => ({
      size: parseInt(item.dataset.size),
      items: [item],
    })

    const addToRow = (row, item) => {
      row.size += parseInt(item.dataset.size)
      row.items.push(item)
    }

    const grid = []

    items.forEach(item => {
      const firstRowThisItemCanFitInto = grid.find(
        row => row.size + parseInt(item.dataset.size) <= columns
      )

      if (!firstRowThisItemCanFitInto) {
        grid.push(newRow(item))
      } else {
        addToRow(firstRowThisItemCanFitInto, item)
      }
    })

    return grid.map(row => row.items).flat()
  }

  function orderItems() {
    const columns = window.innerWidth >= breakpoints.md ? 6 : 3

    ref.mainCollection.forEach(element => {
      const items = [...element.querySelectorAll('[data-size]')]
      const itemsInOriginalOrder = items.sort(sortByIdx)
      const itemsInEvenGrid = sortIntoEvenGrid(itemsInOriginalOrder, columns)

      itemsInEvenGrid.forEach(item => element.append(item))
    })
  }

  orderItems()

  useEvent.listen(events.window.resize, orderItems)
}
