import { useRefs } from "../framework"

export default ref => {
  if (!ref.newsletter) return

  ref.newsletter.forEach(newsletter => {
    const refs = useRefs({ target: newsletter })
    
    refs.submitButton.addEventListener('click', () => {
      const form = newsletter.querySelector('form')
      const formData = new FormData(form)
      if (!formData.has('tac')) {
        refs.tacError.classList.add('active')
      } else {
        refs.tacError.classList.remove('active')
      }
    })
  })
}
