export default ref => {
  if (!ref.customCursor) return

  ref.customCursor.forEach(cursor => {
    const { type } = cursor.dataset

    if (type === 'cylindo') {
      function init() {
        const cylindoEl = document.getElementById('cylindo-viewer')
        const targetEl = cylindoEl.querySelector('.cylindo-threesixty-list')
        const zoomEl = cylindoEl.querySelector('.cylindo-zoom-imagery')

        if (!targetEl || !zoomEl) {
          // wait until cylindo is loaded
          setTimeout(init, 1000)
          return
        }

        targetEl.style.cursor = 'none'
        
        targetEl.addEventListener('mouseenter', () =>
          cursor.classList.add('active')
        )
        targetEl.addEventListener('mousemove', () =>
          cursor.classList.add('active')
        )
        targetEl.addEventListener('mouseleave', () =>
          cursor.classList.remove('active')
        )

        zoomEl.style.cursor = 'none'
        
        zoomEl.addEventListener('mouseenter', () =>
          cursor.classList.add('zoomed')
        )
        zoomEl.addEventListener('mousemove', () =>
          cursor.classList.add('zoomed')
        )
        zoomEl.addEventListener('mouseleave', () =>
          cursor.classList.remove('zoomed')
        )
      }

      init()
    }
  })

  function moveCursors(e) {
    ref.customCursor.forEach(cursor => {
      cursor.style.setProperty('--mouseX', e.clientX + 'px')
      cursor.style.setProperty('--mouseY', e.clientY + 'px')
      // const { type, targetId } = cursor.dataset

      // if (type === 'cylindo') {
      // }
    })
  }

  window.addEventListener('mousemove', moveCursors)
}
