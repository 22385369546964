export default ref => {
  const links = [...document.querySelectorAll('a')]

  links.forEach(link => {
    link.classList.remove('active', 'current')

    if (location.pathname.includes(link.href.replace(location.origin, ''))) {
      link.classList.add('active')
    }

    if (location.pathname === link.href.replace(location.origin, '')) {
      link.classList.add('current')
    }
  })
}
