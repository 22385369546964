import { useRefs, useEvent } from './../framework'
import { events } from './../events'
import { debounce, toggleBurgerIcon } from './../utils'
// import { getInstances } from './cylindo-custom-viewer'

export default ref => {
  if (!ref.leftMenu) return

  let refs = useRefs({ target: ref.leftMenu[0], asArray: true })

  function closeAll({ except } = {}) {
    refs.triggers.map(trigger => {
      if (trigger === except) return

      trigger.classList.remove('open')
      trigger.parentElement.querySelector('ul')?.classList.remove('open')
    })
  }

  function toggleMenu(to) {
    refs.root.classList.toggle('open', to)

    const open = refs.root.classList.contains('open')

    toggleBurgerIcon(refs.trigger[0], open)

    useEvent.dispatch(events.menu.toggled, { menu: 'left', open })

    //open menu with first item already 'selected'

    // if (open) {
    //   const menuWithActiveChild = refs.triggers.find(trigger => {
    //     return trigger.parentElement.querySelector('.active')
    //   })

    // if (menuWithActiveChild) {
    //   closeAll({ except: menuWithActiveChild })
    //   menuWithActiveChild.classList.add('open')
    //   menuWithActiveChild.nextElementSibling?.classList.add('open')
    //   menuWithActiveChild.style.color = 'red'
    // }
    // }

    if (!open) {
      closeAll()
    }
  }

  refs.trigger[0].addEventListener('mousemove', () => toggleMenu(true))
  refs.trigger[0].addEventListener('click', () => {
    if (!refs.root.classList.contains('open')) {
      toggleMenu()
    }
  })

  const toggleMenuDebounced = debounce(trigger => {
    if (!trigger) {
      closeAll()
      return
    }

    closeAll({ except: trigger })

    trigger.classList.add('open')
    trigger.parentElement.querySelector('ul')?.classList.add('open')
  }, 200)

  refs.triggers?.map(trigger => {
    const childList = trigger.parentElement.querySelector('ul.level-3')

    if (childList) {
      childList.addEventListener('mouseenter', () => {
        toggleMenuDebounced(trigger)
      })
    }

    trigger.addEventListener('mouseenter', () => {
      toggleMenuDebounced(trigger)
    })
  })

  // refs.cylindoTrigger?.forEach(element => {
  //   const cylindoElements = [
  //     ...element.parentElement.querySelectorAll('.cylindo-custom-viewer'),
  //   ]

  //   element.addEventListener('mouseenter', () => {
  //     const [cylindoInstance] = getInstances().filter(instance =>
  //       cylindoElements.find(i => i.id === instance.id)
  //     )

  //     if (!cylindoInstance) return

  //     cylindoInstance.instance.rotate(360, 1200)
  //   })
  // })

  function handleClose({ detail }) {
    if (detail.menu === 'left' || detail.open) return
    toggleMenu(false)
    toggleMenuDebounced()
    // closeAll()
  }

  useEvent.listen(events.blur.hovered, handleClose, { global: true })
  useEvent.listen(events.key.esc, handleClose, { global: true })
  useEvent.listen(events.menu.toggled, handleClose, { global: true })

  return () => (refs = null)
}
