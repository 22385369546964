import { events } from '../events'
import { useEvent } from '../framework'
import { isMobile } from '../utils'
import norr11Carousel from './norr11-carousel-snap'
import { scrollLock } from './scroll-lock'

const shouldRun = items => {
  if (isMobile() && items.length >= 3) return true
  if (!isMobile() && items.length >= 5) return true

  return false
}

function addClickListeners(elements) {
  elements.forEach(item => {
    const images = item.querySelectorAll('img')
    images.forEach(image => {
      image.addEventListener('click', () => {
        openLightbox(image)
      })
    })
  })
}

function removeClickListeners(elements) {
  elements.forEach(item => {
    const images = item.querySelectorAll('img')
    images.forEach(image => {
      image.removeEventListener('click', () => {
        openLightbox(image)
      })
    })
  })
}

function openLightbox(img) {
  scrollLock(true)
  const lightbox = document.querySelector('.lightbox')
  lightbox.querySelector('.image').innerHTML = `
      <img src="${img.src}" alt="${img.alt}" />
    `
  setTimeout(() => {
    if (lightbox.querySelector('.image').innerHTML === '') return
    lightbox.classList.add('active')
  }, 100)

  lightbox.addEventListener('click', () => {
    lightbox.classList.remove('active')
    setTimeout(() => {
      lightbox.querySelector('.image').innerHTML = ''
      scrollLock(false)
    }, 500)
  })
}

export default (ref, __devmode) => {
  if (!ref.gallery) return
  const instances = ref.gallery
    .map(element => {
      const { duration } = element.dataset

      const items = element.querySelectorAll('li')

      addClickListeners(items)

      return norr11Carousel({
        el: element,
        speed: parseInt(duration == 0 ? 6500 : duration),
        shouldRun: shouldRun(items),
      })
    })
    .filter(Boolean)


  const items = ref.gallery[0].querySelectorAll('li')
  addClickListeners(items)

  function handleResize() {
    instances.forEach(instance => {
      if (!shouldRun(instance.initialChildren)) {
        instance.destroy()
        const items = ref.gallery[0].querySelectorAll('li')
        removeClickListeners(items)
        addClickListeners(items)

        return
      }

      if (instance.el.classList.contains('destroyed')) {
        const items = ref.gallery[0].querySelectorAll('li')
        instance.init()
        addClickListeners(items)
      }
    })
  }

  useEvent.listen(events.window.resize, handleResize)

  return () => {
    if (__devmode) {
      console.log('gallery teardown start')
    }

    instances.forEach(instance => {
      instance.destroy(2000)
    })

    if (__devmode) {
      console.log('gallery teardown finish')
    }
  }
}
