export default ref => {
  if (!ref.showroom) return

  ref.showroom.forEach(showroom => {
    const buttons = [...showroom.querySelectorAll('button')]

    function handleClosing(e) {
      if (e.target.closest('button') || e.target.closest('.showroom-popup')) {
        return
      }

      showroom.classList.remove('open')
    }

    function handleOpening() {
      showroom.classList.toggle('open')
    }

    showroom.addEventListener('click', handleClosing)
    
    buttons.forEach(button => {
      button.addEventListener('click', handleOpening)
    })
  })
}
