import { useApp } from '../framework'

export default ref => {
  if (!ref.setTransition) return

  const teardown = ref.setTransition.map(item => {
    function setTransition() {
      useApp.transition.set(item.dataset?.transition, location.search)
    }

    item.addEventListener('click', setTransition)

    return () => {
      item.removeEventListener('click', setTransition)
    }
  })

  return teardown
}
