export default (ref, __devmode) => {
  if (!ref.fadingCarousel) return

  const teardowns = ref.fadingCarousel.map(element => {
    let activeIndex = 0
    const items = [...element.children]
    const { duration = 2000 } = element.dataset

    function toggle() {
      items.forEach((item, idx) => {
        item.classList.toggle('active', activeIndex === idx)
      })

      activeIndex = (activeIndex + 1) % items.length
    }

    const interval = setInterval(toggle, parseInt(duration))

    toggle()

    return () => {
      clearInterval(interval)
    }
  })

  return () => {
    if (__devmode) {
      console.log('fading carousel teardown start')
    }

    teardowns.forEach(teardown => teardown())

    if (__devmode) {
      console.log('fading carousel teardown finish')
    }
  }
}
