export default ref => {
    if (!ref.menuMarquee) return

    function init(element) {
        // console.log('init')
        const root = document.documentElement;
        const marqueeElementsDisplayed = getComputedStyle(root).getPropertyValue("--marquee-elements-displayed");
        const marqueeContents = element.children;
        const marqueeContent = marqueeContents[0];

        root.style.setProperty("--marquee-elements", marqueeContent.children.length);

        for (let i = 0; i < marqueeElementsDisplayed; i++) {
            marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
        }
    }


    init(ref.menuMarquee[0])


}
