import defaultTransition from './default.js'
import darknessTransition from './darkness.js'
import lightnessTransition from './lightness.js'
import productCardTransition from './product-card.js'
import fadeTransition from './fade.js'
import defaultLeftTransition from './default-left.js'
import pageLoadTransition from './page-load.js'
import replaceTransition from './replace.js'

/**
 * key defines how the transition is chosen:
 * window.norr11App.transition = { name: [key] }
 */
export default {
  pageLoad: pageLoadTransition,
  fade: fadeTransition,
  default: defaultTransition,
  defaultBack: defaultLeftTransition,
  darkness: darknessTransition,
  lightness: lightnessTransition,
  productCard: productCardTransition,
  defaultLeft: defaultLeftTransition,
  replace: replaceTransition
}
