import { useApp, useHydrate, useRefs } from '../framework'
import { debounce } from '../utils'

export default ref => {
  if (!ref.predictiveSearch) return

  const instances = ref.predictiveSearch.map(element => {
    const refs = useRefs({ target: element })
    const resultsRefs = useRefs({ target: refs.results, watch: true })

    const cachedResults = {}

    refs.form.addEventListener('submit', onFormSubmit)
    refs.input.addEventListener('input', debounce(onChange, 300))

    refs.input.focus()

    element.addEventListener('keyup', onKeyup)
    element.addEventListener('keydown', onKeydown)

    const { components } = useApp.components.get()
    let hydration = useHydrate(components)

    function onRender() {
      setTimeout(() => {
        hydration = hydration.hydrate(resultsRefs.current)
      })
    }

    function getQuery() {
      return refs.input.value.trim()
    }

    function onChange() {
      const searchTerm = getQuery()

      getSearchResults(searchTerm)
    }

    function onFormSubmit(event) {
      if (
        !getQuery().length ||
        element.querySelector('[aria-selected="true"] a')
      )
        event.preventDefault()
    }

    function onKeyup(event) {
      event.preventDefault()

      switch (event.code) {
        case 'ArrowUp':
          switchOption('up')
          break
        case 'ArrowDown':
          switchOption('down')
          break
        case 'Enter':
          selectOption()
          break
      }
    }

    function onKeydown(event) {
      // Prevent the cursor from moving in the input when using the up and down arrow keys
      if (event.code === 'ArrowUp' || event.code === 'ArrowDown') {
        event.preventDefault()
      }
    }

    function switchOption(direction) {
      if (!element.getAttribute('open')) return

      const moveUp = direction === 'up'
      const selectedElement = element.querySelector('[aria-selected="true"]')
      const allElements = element.querySelectorAll('li')
      let activeElement = element.querySelector('li')

      if (moveUp && !selectedElement) return

      refs.status.textContent = ''

      if (!moveUp && selectedElement) {
        activeElement = selectedElement.nextElementSibling || allElements[0]
      } else if (moveUp) {
        activeElement =
          selectedElement.previousElementSibling ||
          allElements[allElements.length - 1]
      }

      if (activeElement === selectedElement) return

      activeElement.setAttribute('aria-selected', true)
      if (selectedElement) selectedElement.setAttribute('aria-selected', false)

      refs.input.setAttribute('aria-activedescendant', activeElement.id)
    }

    function selectOption() {
      const selectedProduct = element.querySelector(
        '[aria-selected="true"] a, [aria-selected="true"] button'
      )

      if (selectedProduct) selectedProduct.click()
    }

    async function getSearchResults(searchTerm) {
      const queryKey = searchTerm.replace(' ', '-').toLowerCase()
      setLiveRegionLoadingState()

      if (cachedResults[queryKey]) {
        renderSearchResults(cachedResults[queryKey])
        return
      }

      try {
        const response = await fetch(
          `${routes.predictive_search_url}?q=${encodeURIComponent(
            searchTerm
          )}&${encodeURIComponent(
            'resources[type]'
          )}=product&${encodeURIComponent(
            'resources[limit]'
          )}=4&section_id=predictive-search-results`
        )

        if (!response.ok) {
          throw new Error(response.status)
        }

        const text = await response.text()

        const resultsMarkup = new DOMParser()
          .parseFromString(text, 'text/html')
          .querySelector('#shopify-section-predictive-search-results').innerHTML
        cachedResults[queryKey] = resultsMarkup
        renderSearchResults(resultsMarkup)
      } catch (error) {
        throw error
      }
    }

    function setLiveRegionLoadingState() {
      element.setAttribute('loading', true)
    }

    function renderSearchResults(resultsMarkup) {
      const searchResults = document.querySelector('.main-search-results')
      if (searchResults) {
        searchResults.remove()
      }
      refs.results.innerHTML = resultsMarkup
      element.setAttribute('results', true)

      setLiveRegionResults()

      onRender()
    }

    function setLiveRegionResults() {
      element.removeAttribute('loading')
    }

    return { refs, hydration }
  })

  return () => {
    instances.forEach(({ refs, hydration }) => {
      refs = null
      hydration.dehydrate()
    })
  }
}
