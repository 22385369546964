// VOCAST RESIZING SCRIPT

export default () => {
  window.addEventListener('hashchange', function () {
    const element = document.getElementById('digitalshowroom')

    if (element) {
      element.contentWindow.postMessage('route=' + location.hash, '*')
    }
  })

  window.reportDimentions = function (event) {
    var element = document.getElementById('digitalshowroom')

    if (element) {
      var bounding = element.getBoundingClientRect()
      var elementWidth = parseInt(element.style.width, 10)
      var elementHeight = parseInt(element.style.height, 10)
      // Calculate the rect of the iframe that is currently visible on screen
      //var visibleTop = bounding.top < 0 ? -bounding.top : 0;
      var visibleLeft = bounding.left < 0 ? -bounding.left : 0
      var visibleWidth =
        window.innerWidth - (bounding.left > 0 ? bounding.left : 0)
      if (visibleWidth > elementWidth) visibleWidth = elementWidth
      var visibleHeight =
        window.innerHeight - (bounding.top > 0 ? bounding.top : 0)
      if (visibleHeight > elementHeight) visibleHeight = elementHeight
      var scrollYdefaultHeight = 40
      var scrollY = event?.currentTarget.window
        ? event.currentTarget.window.scrollY
        : scrollYdefaultHeight
      var visibleRect = {
        top: scrollY <= scrollYdefaultHeight ? scrollYdefaultHeight : scrollY,
        left: visibleLeft,
        width: visibleWidth,
        height: visibleHeight,
      }
      if (document.getElementById('digitalshowroom')) {
        document
          .getElementById('digitalshowroom')
          .contentWindow.postMessage(
            { message: 'scroll', visibleRect: visibleRect },
            '*'
          )
      }
    }
  }
  // Force a iframe height to fit the modal. If only one item in basket the iframe height is too lov to fit the modal.
  window.minResizeFrame = function () {
    if (document.getElementById('digitalshowroom')) {
      document.getElementById('digitalshowroom').style.minHeight = '600px'
    }
  }
  window.addEventListener('scroll', event => reportDimentions(event))
  window.addEventListener('resize', event => reportDimentions(event))

  if (document.getElementById('digitalshowroom')) {
    document.getElementById('digitalshowroom').src =
      'https://norr11iframe.presscloud.com/digitalshowroom' + location.hash
  }

  var iframes = iFrameResize(
    {
      scrolling: true,
      checkOrigin: false,
      heightCalculationMethod: 'taggedElement',
      initCallback: function () {
        window.minResizeFrame()
      },
    },
    '#digitalshowroom'
  )

  window.reportDimentions(event)

  window.addEventListener(
    'message',
    function (event) {
      if (event.data && typeof event.data === 'string') {
        let routeString = 'routeChanged='
        let hasRouteChanged = event.data.indexOf(routeString)
        if (hasRouteChanged !== -1) {
          let routeHash = '#' + event.data.substring(routeString.length)
          if (routeHash !== location.hash) {
            window.scrollTo(0, 0)
            history.replaceState(null, '', routeHash)
          }
        }
      }
    },
    false
  )
}
