import { useEvent } from '../framework'
import { events } from '../events'
import { closeAllMenus } from '../utils'

export default ref => {
  if (!ref.header) return

  const BLUR_TRIGGER = 70
  let isBlurry = false

  function toggleBlur(to) {
    ref.headerBlur[0].classList.toggle('active', to)
    isBlurry = to
  }

  function setBlurry() {
    if (window.scrollY <= BLUR_TRIGGER) {
      toggleBlur(false)
    }
    
    if (!isBlurry && window.scrollY > BLUR_TRIGGER) {
      toggleBlur(true)
    }
  }

  setBlurry()

  useEvent.listen(events.window.scroll, setBlurry, { global: true })
  useEvent.listen(events.window.resize, () => closeAllMenus(ref), { global: true })

  useEvent.listen(
    events.menu.toggled,
    ({ detail }) => {
      const allMenus = ['right', 'left', 'mobile']

      ref.header[0].classList.remove(...allMenus)
      ref.header[0].classList.toggle('open', detail.open)

      if (detail.open) {
        ref.header[0].classList.add(detail.menu)
      }

      // if (detail.menu === 'mobile') {
      //   toggleBlur(detail.open)
      // }
    },
    { global: true }
  )
}
