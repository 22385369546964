import useApp from './use-app.js'
import useScrollRestoration from './use-scroll-restoration.js'

export default ({ refs, transitions, globals }) => {
  const runCurrentHook = async (hook, data) => {
    const currentTransition = useApp.transition.get()?.name
    const currentHooks = transitions[currentTransition]

    /**
     * no transition set, or the name doesn't exist.
     */
    if (!currentTransition || !currentHooks) {
      /**
       * run nothing if not even the default-transition exists..
       */
      if (!transitions.default[hook]) {
        return Promise.resolve()
      }

      /**
       * run default-transition.
       */
      if (data.trigger === 'back') {
        return transitions.defaultBack[hook](data, refs)
      } else {
        return transitions.default[hook](data, refs)
      }
    }

    /**
     * a transition was set, but doesn't exist
     */
    if (typeof currentHooks[hook] !== 'function') {
      return Promise.resolve()
    }

    /**
     * a transition was set, and exists, run it
     */
    return currentHooks[hook](data, refs)
  }

  const runGlobalHook = (hook, data) => {
    if (typeof globals[hook] === 'function') {
      return globals[hook](data, refs)
    }
  }

  return [
    {
      name: 'main',
      before() {
        runCurrentHook('before')
      },
      async leave(data) {
        useScrollRestoration.save()
        await runCurrentHook('leave', data)
        runGlobalHook('leave', data)
      },
      async enter(data) {
        const navigatingBack = ['back', 'popstate'].includes(data.trigger)
        useScrollRestoration.restore({ toTop: !navigatingBack })

        await runCurrentHook('enter', data)
        runGlobalHook('enter', data)

        /**
         * cleanup any transition
         */
        useApp.transition.set(null)
        data.next.container.removeAttribute('style')
      },
      after(data) {
        runGlobalHook('after', data)
      },

      once(data) {
        runGlobalHook('once', data)
      },
    },
  ]
}
